import React from "react";

const experiences = [
  {
    company: "RPMGlobal",
    role: "IT Engineer",
    date: "April 2022 - Present",
    link: "https://www.rpmglobal.com",
    responsibilities: [
      "Creator and owner of the Test Lab.",
      "Developed performance testing processes using Azure Load Testing, JMeter, and StresStimulus.",
      "Found open-source tools and developed custom Powershell scripts to be used for testing",
      "Implemented mobile app testing with Bluestack, reducing the need for physical devices.",
      "Deployed and managed Synopsys Seeker (IAST).",
      "Managed ESXi hosts and VMs designed to mimic customer environments.",
      "Automated infrastructure tasks with Ansible, PowerShell, Terraform, and Rundeck.",
      "Monitored systems with Zabbix (integrated with Teams) and handled VM backups with Iperius Backup.",
      "Managed Azure DevOps organization and utilised GitHub for source control.",
      "Mentored a junior colleague to support Test Lab operations and ensure continuity."
    ]
  },
  {
    company: "RSL Queensland",
    role: "IT Support Officer",
    date: "July 2021 - April 2022",
    link: "https://www.rslqld.org",
    responsibilities: [
      "Provided technical support for RSL Queensland staff, Mates4Mates staff, and 200 sub-branches.",
      "Supported Office 365, Mimecast, Power Apps, Intune, and ServiceNow.",
      "Gained experience in change management and cross-team collaboration with security, development, and systems engineering teams.",
      "Participated in a large-scale project to deliver smartphones and training to sub-branches while maintaining Help Desk duties."
    ]
  },
  {
    company: "Sentrian",
    role: "Level 1 and 2 Service Desk Engineer",
    date: "February 2018 - July 2021",
    link: "https://www.sentrian.com.au",
    responsibilities: [
      "Delivered Level 1 and 2 support for clients in accounting, medical, and legal sectors.",
      "Supported Active Directory, 3CX, Office 365, Duo, Okta, VMware, and Remote Desktop Servers.",
      "Built strong customer relationships through effective troubleshooting and proactive issue resolution.",
      "Recognized as Employee of the Month multiple times for outstanding performance."
    ]
  },
  {
    company: "Theta Technologies",
    role: "Software Support Officer",
    date: "June 2016 - December 2016",
    link: "https://thetatechnologies.com.au",
    responsibilities: [
      "Provided support for InformationLeader customers, troubleshooting issues via phone and screen sharing.",
      "Diagnosed backend server issues, scheduled software upgrades, and logged/testing bugs and features.",
      "Automated manual testing processes using Selenium to improve efficiency."
    ]
  }
];

export default function WorkHistory() {
  return (
    <section id="workhistory" className="workhistory-section">
      <h2>Work History</h2>
      {experiences.map((exp, index) => (
        <div className="workhistory-card" key={index}>
          <h3 className="company-name">
            <a href={exp.link} target="_blank" rel="noopener noreferrer" className="company-link">
              {exp.company}
            </a>
          </h3>
          <p className="role">{exp.role}</p>
          <p className="date">{exp.date}</p>
          <ul className="responsibilities">
            {exp.responsibilities.map((task, idx) => (
              <li key={idx}>{task}</li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
}