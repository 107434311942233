import React, { useState, useEffect } from "react";

const VisitorCounter = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_FUNCTION_APP_API);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCount(data.count);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCount();
  }, []);

  return (
    <div className="visitor-counter">
      {loading ? (
        <span>Loading...</span>
      ) : error ? (
        <span className="visitor-error">Visitor count unavailable</span>
      ) : (
        <span>Visitor Count: {count.toLocaleString()}</span>
      )}
    </div>
  );
};

export default VisitorCounter;