import React from "react";

export default function Education() {
  return (
    <section id="education" className="education-section">
      <h2>Education</h2>
      <div className="education-card">
        <h3>TAFE Southbank</h3>
        <p className="date">2016</p>
        <p>Certificate III of Information, Digital Media, and Technology in Networking</p>
      </div>
      <div className="education-card">
        <h3>TAFE Southbank</h3>
        <p className="date">2017</p>
        <p>Diploma of Information Technology in Networking</p>
      </div>
    </section>
  );
}