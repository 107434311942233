import React from "react";
import VisitorCounter from "./VisitorCounter";

export default function Header() {
  return (
    <header className="header">
      <nav className="nav-links">
        <a href="https://www.linkedin.com/in/amaniel-gobezy-bb9b43119" target="_blank" rel="noopener noreferrer">LinkedIn</a> | 
        <VisitorCounter /> | 
        <a href="https://dev.to/amaniel" target="_blank" rel="noopener noreferrer">Blog</a>
      </nav>

      
    </header>
  );
}