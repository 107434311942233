import React from "react";

const certificates = [
  {
    provider: "Microsoft",
    date: "2023",
    title: "AZ-104: Azure Administrator Associate",
    link: "https://learn.microsoft.com/en-au/users/amanielgobezy-4363/credentials/94c62e05109203a0"
  },
  {
    provider: "Microsoft",
    date: "2024",
    title: "AZ-400: Azure DevOps Engineer Expert",
    link: "https://learn.microsoft.com/en-au/users/amanielgobezy-4363/credentials/9d29d36e9d62e0c2"
  },
  {
    provider: "Microsoft",
    date: "2024",
    title: "AZ-500: Azure Security Engineer Associate",
    link: "https://learn.microsoft.com/en-au/users/amanielgobezy-4363/credentials/b17c801170c48520"
  }
];

export default function Certificates() {
  return (
    <section id="certificates" className="certificates-section">
      <h2>Certificates</h2>
      {certificates.map((cert, index) => (
        <div className="certificate-card" key={index}>
          <h3>
            <a
              href={cert.link}
              target="_blank"
              rel="noopener noreferrer"
              className="certificate-link"
            >
              {cert.provider}
            </a>
          </h3>
          <p className="date">{cert.date}</p>
          <p>{cert.title}</p>
        </div>
      ))}
    </section>
  );
}