import React from "react";
import Header from "./components/Header";
import Education from "./components/Education";
import Certificates from "./components/Certificates";
import Footer from "./components/Footer";
import WorkHistory from "./components/WorkHistory";

export default function App() {
  return (
    <div>
      <Header />
      <main>
        <WorkHistory />
        <Education />
        <Certificates />
      </main>
      <Footer />
    </div>
  );
}